import React from "react";

const RepaymentChargeFAQ = () => ([
  {
    title: 'What is an early repayment charge (ERCs)?',
    description: (
      <>
        An early repayment charge ERC is charged by the lender, as a result of paying off your mortgage early before your mortgage deal ends, going over your annual overpayment allowance or exiting your existing mortgage early for a new mortgage deal.
      </>
    ),
  },
  {
    title: 'Why do lenders impose early repayment mortgage charges?',
    description: (
      <>
        Lenders use early repayment charges to prevent you from exiting your current mortgage deal with them to switch to a new mortgage lender, so they introduce ERCs as a tie-in period.<br/><br/>
        If you pay off your mortgage deal early, the lender loses out on potential interest earnings that were factored into the loan terms.
      </>
    ),
  },
  {
    title: 'Are early repayment charges common?',
    description: (
      <>
        Yes, most mortgages have mortgage early repayment charges associated with the mortgage agreement, especially with fixed-rate mortgages as you have the security of how much you'll pay during the mortgage term.
      </>
    ),
  },
  {
    title: 'Do all loans have an early repayment charge?',
    description: (
      <>
        Not all mortgage deals have an early repayment charge. They are more common with fixed-rate mortgages and certain personal loans, but other types of loans might have their own prepayment terms. It's crucial to review your loan agreement for specific details.
      </>
    ),
  },
  {
    title: 'Can early repayment charges be avoided?',
    description: (
      <>
        If you want to avoid an early repayment charge, you will need to either wait until your mortgage term comes to an end on your existing deal before switching to a new mortgage. <br />
        Also, you will need to be aware of your overpayment limits to ensure you do not exceed this to ensure you can avoid early repayment charges.
      </>
    ),
  },
  {
    title: 'When do early repayment charges typically apply?',
    description: (
      <>
        If you want to pay your mortgage early you are more than likely going to incur an early repayment charge. Early repayment charges usually apply during the initial fixed-rate period of a mortgage or a set period after taking out the mortgage. Once this period ends, you might have more flexibility to repay without incurring penalties.
      </>
    ),
  },
  {
    title: 'How can I find out if my loan has early repayment charges?',
    description: (
      <>
        Any early repayment charges associated with your mortgage will be stated and outlined in your loan agreement or mortgage contract. If you're unsure, contact your lender to clarify the details.
      </>
    ),
  },
  {
    title: 'Can early repayment charges change over time?',
    description: (
      <>
        Early repayment charges are typically fixed when you sign the loan agreement. They don't usually change during the specified period. However, it's essential to verify this information with your lender and carefully read the terms to avoid surprises.
      </>
    ),
  },
]);

export default RepaymentChargeFAQ;
